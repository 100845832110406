//#region Components
import React from 'react'

import styled from '@emotion/styled'
import { ClassNames, css } from '@emotion/react'

import { translate } from 'components/shared/internationalization'

import { Field } from 'react-final-form'
import { ColorBaseBlueMedium, AtlasBlackNearVar, ColorBaseSkyBlue } from 'assets/styles/variables'
import { DeleteDateRowIcon as IconDeleteDateRow } from 'assets/icons/DeleteDateRowIcon'
import { format, isDate, parse } from 'date-fns'

import { IconButton, DatePicker } from 'components/shared/StyledComponents'
import FormControl from '@material-ui/core/FormControl/FormControl'
import { AtlasIcon } from '@diligentcorp/atlas-react/dist/components'
import { InputLabelStyled } from 'components/minutetaker/components/form/AssigneeChipInputField'
import moment from 'moment'
import i18n from 'common/util/internationalization'

//#endregion

//#region Props

type Props = {
    dateInputMask: any
    error: any
    className?: any
    labelText?: string
}

//#endregion

//#region Implementation
export class DateField extends React.Component<Props> {
    datePicker?: any

    getDueDateIcon = (dueDate, onChange): any | null => {
        if (dueDate) {
            return (
                <IconButton
                    onMouseDown={() => {
                        onChange('')
                    }}>
                    <IconDeleteDateRow />
                </IconButton>
            )
        }

        return null
    }

    render() {
        const { dateInputMask } = this.props

        //const currentLocale = locale()
        //const firstDayOfWeek = localeData(currentLocale).firstDayOfWeek()

        return (
            <Field
                name="action_date"
                className={this.props.className}
                render={({ input: { onChange, value } }) => {
                    //const shortDate = format(value, 'L')

                    const dateText = isDate(value) ? format(value, dateInputMask) : value

                    const dateTextMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

                    //const dueDateObject = value && isDate(value) ? toDate(value) : null

                    //const hintText = dateText ? dateText : dateInputMask
                    return (
                        <ClassNames>
                            {({ css }) => (
                                <DateFieldContainer className={this.props.className}>
                                    <div
                                        className={css`
                                            .Mui-focusVisible {
                                                box-shadow: 0px 0px 0px 2px #3e95fa;
                                                border-radius: 2px;
                                            }
                                            .MuiIconButton-root {
                                                padding: 8px;
                                                margin-right: -4px;
                                                width: 39px;
                                                height: 39px;
                                            }
                                            .MuiTouchRipple-root {
                                                display: none;
                                            }
                                        `}>
                                        <FormControl className={this.props.className}>
                                            <InputLabelStyled
                                                className={css`
                                                    margin-top: 1.3rem;
                                                `}
                                                htmlFor="due-date">
                                                {translate(
                                                    this.props.labelText ??
                                                        'SEND_INVITATION_DUE_DATE'
                                                )}
                                            </InputLabelStyled>
                                            <DatePicker
                                                className={css`
                                                    ${AddActionDatePickerStyle}
                                                `}
                                                invalidDateMessage={
                                                    !!this.props.error ? this.props.error : null
                                                }
                                                keyboard
                                                id="datePicker"
                                                variant="outlined"
                                                disableOpenOnEnter
                                                minDateMessage={translate('MINIMUM_DATE_MESSAGE')}
                                                maxDateMessage={translate('MAXIMUM_DATE_MESSAGE')}
                                                format={dateInputMask}
                                                placeholder={dateInputMask.toUpperCase()}
                                                onChange={(value) => onChange(value)}
                                                mask={(value) => (value ? dateTextMask : [])}
                                                keyboardIcon={
                                                    <AtlasIcon
                                                        name="calendar"
                                                        color={AtlasBlackNearVar}
                                                        onClick={() => {
                                                            const parsedDate = parse(
                                                                dateText,
                                                                dateInputMask,
                                                                new Date()
                                                            )
                                                            if (isDate(parsedDate)) {
                                                                onChange(parsedDate)
                                                            }
                                                        }}
                                                    />
                                                }
                                                value={value}
                                                InputProps={{
                                                    onChange: (event) => onChange(event),
                                                    value: dateText,
                                                    id: 'dateText'
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': i18n.t('DATE_PICKER')
                                                }}
                                            />
                                        </FormControl>
                                    </div>

                                    <div
                                        className={css`
                                            margin-top: 15px;
                                            .MuiTouchRipple-root {
                                                display: none;
                                            }
                                            .MuiButtonBase-root.MuiIconButton-root {
                                                margin-left: 5px;
                                                margin-top: 2.2rem;
                                                padding: 2px;
                                            }
                                            .Mui-focusVisible {
                                                box-shadow: 0px 0px 0px 2px ${ColorBaseSkyBlue};
                                                border-radius: 2px;
                                                padding: 2px;
                                            }
                                        `}>
                                        {moment(value, dateInputMask).isValid() && (
                                            <IconButton
                                                onKeyPress={() => {
                                                    onChange('')
                                                }}
                                                onClick={() => {
                                                    onChange('')
                                                }}>
                                                <IconDeleteDateRow />
                                            </IconButton>
                                        )}
                                    </div>
                                </DateFieldContainer>
                            )}
                        </ClassNames>
                    )
                }}
            />
        )
    }
}

//#endregion

//#region Styles
const DateFieldContainer = styled('div')`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -20px;
`
export const getDialogTitleStyle = () => {
    return {
        backgroundColor: ColorBaseBlueMedium,
        color: '#fff',
        height: 50,
        fontSize: 18,
        padding: 0,
        paddingLeft: 25,
        lineHeight: '50px',
        fontWeight: 'normal',
        marginBottom: 0
    }
}

export const AddActionDatePickerStyle = css`
    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #949494;
    }
    .MuiOutlinedInput-adornedEnd {
        min-height: 40px;
        margin-top: 4px;
    }
    .MuiOutlinedInput-input {
        padding: 10px 16px;
    }
    p {
        margin-left: 0px;
    }
`

//#endregion

export default DateField
